import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    dateModeOptions = [
      Enums.dateModes.currentMonth,
      Enums.dateModes.lastMonth,
      Enums.dateModes.lastThirtyDays,
      Enums.dateModes.previousThreeMonths,
      Enums.dateModes.previousTwelveMonths
    ]
    showEntireOrgHierarchyForAllUsers = false;
    defaultMonthDateMode: DateMonthModes = 'sales';
    filterTypeLookup: { [id: string]: FilterType } = {
        'org': { displayName: 'Hierarchy', name: 'org' },
        'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
        'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
        'trade-in-provider': { displayName: 'Provider', name: 'trade-in-provider' },
        'device-type': { displayName: 'Device Type', name: 'device-type' },
        'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
        'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
        'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
        'source-type': { displayName: 'Source Type', name: 'source-type' },
        'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
        'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
        'website-provider': { displayName: 'Provider', name: 'website-provider' },
        'service-scheduler-provider': { displayName: 'Provider', name: 'service-scheduler-provider' },
        'role': { displayName: 'Role', name: 'role' },
        'page': { displayName: 'Page', name: 'page' },
    };

    filterReportConfigs: { [id: string]: FilterConfigReport } = {
        // Program Overview
        'ProgramOverview': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: 'ProgramOverview',
            filters: ['org', 'device-type'],
            defaults: ['org'],
            section: 'program-overview',
            orgTypes: [1, 4]
        },
        // Digital Retailing
        'DigitalRetailingOverview': {
          reportName: constants.reportNames.digitalRetailingOverview,
          dateMode: Enums.dateModes.currentMonth,
          filters: ['org', 'digital-retailing-provider', 'device-type'],
          defaults: ['org'],
          section: 'provider-tools',
          orgTypes: [1, 4]
        },
        // Trade-In
        'TradeInOverview': {
          reportName: constants.reportNames.tradeInOverview,
          dateMode: Enums.dateModes.currentMonth,
          filters: ['org', 'device-type', 'trade-in-provider'],
          defaults: ['org'],
          section: 'provider-tools',
          orgTypes: [1, 4]
        },
        // Service Scheduler
        'ServiceSchedulerOverview': {
            reportName: 'Service Scheduler Overview',
            dateMode: Enums.dateModes.currentMonth,
          filters: ['org', 'device-type', 'service-scheduler-provider'],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1, 4]
          },
        // Chat
        'ChatOverview': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.chatOverview,
            filters: ['org', 'device-type', 'chat-provider'],
            defaults: ['org'],
            section: 'chat',
            orgTypes: [1, 4]
        },
        'ChatSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.chatSummary,
            filters: [
                'org', 'chat-provider'
            ],
            defaults: [
                'org'
            ],
            section: 'chat',
            orgTypes: [1, 4]
        },
        // Inventory
        'InventoryOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.inventoryOverview,
            filters: [
                'org', 'vehicle'
            ],
            defaults: [
                'org', 'vehicle'
            ],
            section: 'inventory'
        },
        // Leads
        'LeadsOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.leadsOverview,
            filters: ['org'],
            defaults: ['org'],
            section: 'leads'
        },
        'LeadSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadSummary,
            filters: [
                'org', 'lead-type', 'source-type'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'LeadSourcePerformance': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadSourcePerformance,
            filters: [
                'org', 'lead-type', 'source-type', 'vehicle-make'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'LeadEventPerformance': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.leadEventPerformance,
            filters: [
                'org', 'vehicle-make'
            ],
            defaults: [
                'org'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'FacebookPerformance': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.facebookPerformance,
            filters: [
                'org'
            ],
            defaults: [
                'org'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'LeadVehiclePerformance': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadVehiclePerformance,
            filters: [
                'org', 'source-type', 'vehicle-make'
            ],
            defaults: [
                'org', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        'LeadTransactions': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.leadTransactions,
            filters: [
                'org', 'lead-type', 'source-type'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1]
        },
        // Sales
        'SalesOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.salesOverview,
            filters: ['org'],
            defaults: ['org'],
            section: 'sales',
            orgTypes: [1]
        },
        'SalesSummary': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.salesSummary,
            filters: [
                'org', 'sale-type', 'lead-type', 'source-type', 'vehicle-class'
            ],
            defaults: [
                'org', 'sale-type', 'lead-type', 'source-type'
            ],
            section: 'sales',
            orgTypes: [1]
        },
        'SalesVehicleSummary': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.salesVehicleSummary,
            filters: [
                'org', 'lead-type', 'sale-type', 'source-type'
            ],
            defaults: [
                'org', 'lead-type', 'sale-type', 'source-type'
            ],
            section: 'sales',
            orgTypes: [1]
        },
        'SalesForecastingSummary': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.salesForecastingSummary,
            filters: [
              'org', 'sale-type', 'vehicle-model'
            ],
            defaults: [
              'org', 'sale-type'
            ],
            section: 'sales',
            orgTypes: [1]
        },
        // Website
        'WebsiteOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteOverview,
            filters: ['org'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteAfterSales': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteAfterSales,
            filters: [
                'org', 'device-type'
            ],
            defaults: [
                'org', 'device-type'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteCertifiedChat': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteCertifiedChat,
            filters: [
                'org', 'chat-provider'
            ],
            defaults: [
                'org', 'chat-provider'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteLandingPage': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteLandingPage,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org', 'website-provider'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },

        'WebsiteProviderSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteProviderSummary, // org only
            filters: ['org'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteReferrerSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteReferrerSummary,
            filters: [
                'org', 'device-type'
            ],
            defaults: [
                'org', 'device-type'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteScorecard,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteSummary,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        'WebsiteVehicleSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteVehicleSummary,
            filters: [
                'org', 'website-provider', 'device-type'
            ],
            defaults: [
                'org', 'device-type'
            ],
            section: 'website',
            orgTypes: [1, 4]
        },
        'UserActivityOverview': {
            reportName: 'UserActivityOverview',
            dateMode: Enums.dateModes.currentMonth,
            filters: ['role', 'page'],
            defaults: ['role', 'page'],
            section: 'user-activity',
            orgTypes: [1, 4]
        }
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'vehicle': {
            type: 'vehicle',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'trade-in-provider': {
            type: 'trade-in-provider',
            available: [5, 14, 20, 28, 29, 33, 34, 35, 36, 37, 47],
            selected: [
              { value: 5, display: 'Dealer eProcess' },
              { value: 14, display: 'TradePending' },
              { value: 20, display: 'Edmunds' },
              { value: 28, display: 'Modal' },
              { value: 29, display: 'PERQ' },
              { value: 33, display: 'Fullpath' },
              { value: 34, display: 'eAutoAppraise' },
              { value: 35, display: 'Car and Driver' },
              { value: 36, display: 'PureCars' },
              { value: 37, display: 'Trade-In Valet' },
              { value: 47, display: 'Kelley Blue Book' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'chat-provider': {
            type: 'chat-provider',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'digital-retailing-provider': {
            type: 'digital-retailing-provider',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'service-scheduler-provider': {
            type: 'service-scheduler-provider',
            available: [8, 26, 39, 40, 41, 42, 43, 44, 45, 49, 50],
            selected: [
                { value: 8, display: 'AutoLoop' },
                { value: 26, display: 'CDK Global' },
                { value: 39, display: 'Auto Pro Solutions' },
                { value: 40, display: 'Dealer FX' },
                { value: 41, display: 'Time Highway' },
                { value: 42, display: 'Total Customer Connect' },
                { value: 43, display: 'Update Promise' },
                { value: 44, display: 'Reynolds & Reynolds' },
                { value: 45, display: 'Xtime' },
                { value: 49, display: 'Tekion' },
                { value: 50, display: 'eLead' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'device-type': {
            type: 'device-type',
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'lead-type': {
            type: 'lead-type',
            selected: [{ value: 1, display: 'New Vehicle' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'sale-type': {
            type: 'sale-type',
            selected: [
              { value: 1, display: 'New' },
              { value: 2, display: 'TCUV' }
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'source-type': {
            type: 'source-type',
            selected: [
                { value: 1, display: 'Brand' },
                { value: 3, display: 'Dealer Website' },
                { value: 5, display: 'Event' }
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'vehicle-make': {
            type: 'vehicle-make',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-class': {
            type: 'vehicle-class',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-model': {
            type: 'vehicle-model',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
            available: [1, 2, 3, 4, 5, 10, 11, 46, 51],
            selected: [
                { value: 11, display: 'AutoGo' },
                { value: 5, display: 'Dealer eProcess' },
                { value: 1, display: 'Dealer Fire' },
                { value: 2, display: 'Dealer Inspire' },
                { value: 4, display: 'Dealer.com' },
                { value: 3, display: 'DealerOn' },
                { value: 46, display: 'Fox Dealer' },
                { value: 10, display: 'fusionZONE' },
                { value: 51, display: 'TeamVelocity' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'role': {
            type: 'role',
            available: [2, 11],
            selected: [
              { value: 2, display: 'Corporate' },
              { value: 11, display: 'Dealer' },
          ],
            removable: false,
            lockable: true,
            locked: false
        },
        'page': {
            type: 'page',
            available: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            selected: [
              { value: 1, display: 'Website Overview' },
              { value: 2, display: 'Website Activity' },
              { value: 3, display: 'Website Summary' },
              { value: 4, display: 'Referrer Report' },
              { value: 5, display: 'Vehicle Summary' },
              { value: 6, display: 'Website Provider Performance' },
              { value: 7, display: 'Sales Overview' },
              { value: 8, display: 'Digital Retailing Overview' },
              { value: 9, display: 'Trade-In Overview' },
              { value: 10, display: 'Service Scheduler Overview' },
              { value: 11, display: 'Chat Overview' },
              { value: 12, display: 'Dealer Resources'},
          ],
            removable: false,
            lockable: false,
            locked: false
        },
    };
}
