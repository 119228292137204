import { IDealerResourceCategoryConfig, IDealerResourcesConfig } from '../../../../_shared/models/models-index';

export class DealerResourcesConfig implements IDealerResourcesConfig {
    public reportTitle = 'Dealer Resources';
    public disclaimer = '';
    public resourceCategories: IDealerResourceCategoryConfig[] = [
        {
            category: 'Provider Scorecard',
            resourceConfig: [
                {
                    label: 'Provider Scorecard 2023 Q1',
                    imgUrl: '../../assets/images/DealerResources/Toyota/ProviderScorecard/Website_Provider_Scorecard_Q1_2023.jpg',
                    downloadUrl: 'https://toyotausprod.blob.core.windows.net/dealer-resources/provider-scorecard/Website_Provider_Scorecard_Q1_2023.pdf'
                },
                {
                    label: 'Provider Scorecard 2022 Q4',
                    imgUrl: '../../assets/images/DealerResources/Toyota/ProviderScorecard/Website_Provider_Scorecard_Q4_2022.jpg',
                    downloadUrl: 'https://toyotausprod.blob.core.windows.net/dealer-resources/provider-scorecard/Website_Provider_Scorecard_Q4_2022.pdf'
                },
                {
                    label: 'Provider Scorecard 2022 Q3',
                    imgUrl: '../../assets/images/DealerResources/Toyota/ProviderScorecard/Toyota Provider Scorecard (Dealer)_2022_q3.jpg',
                    downloadUrl: 'https://toyotausprod.blob.core.windows.net/dealer-resources/provider-scorecard/Website_Provider_Scorecard_-_Q3_2022.pdf'
                },
                {
                    label: 'Provider Scorecard 2022 Q2',
                    imgUrl: '../../assets/images/DealerResources/Toyota/ProviderScorecard/Toyota Provider Scorecard (Dealer)_2022_q2.jpg',
                    downloadUrl: 'https://toyotausprod.blob.core.windows.net/dealer-resources/provider-scorecard/Website_Provider_Scorecard_-_Q2_2022.pdf'
                },
                {
                    label: 'Provider Scorecard 2022 Q1',
                    imgUrl: '../../assets/images/DealerResources/Toyota/ProviderScorecard/Toyota Provider Scorecard (Dealer)_2022_q1.jpg',
                    downloadUrl: 'https://toyotausprod.blob.core.windows.net/dealer-resources/provider-scorecard/Website_Provider_Scorecard_-_Q1_2022.pdf'
                },
                {
                    label: 'Provider Scorecard 2021 Q4',
                    imgUrl: '../../assets/images/DealerResources/Toyota/ProviderScorecard/Toyota Provider Scorecard (Dealer)_2021_q4.jpg',
                    downloadUrl: 'https://toyotausprod.blob.core.windows.net/dealer-resources/provider-scorecard/Website_Provider_Scorecard_-_Q4_2021.pdf'
                },
            ]
        }
    ];
}
