export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'Toyota',
  hostName: 'www.tmsusainsight.com',
  environmentName: 'production',
  appName: 'Toyota Insight',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '4914cada-2c58-4044-a294-183fc9da0a5d'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 13,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1, 4],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#000', '#ed1c24', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://toyotaus-analytics-api-prod-v2.azurewebsites.net/api',
  authUri: 'https://toyotaus-analytics-api-prod-v2.azurewebsites.net/token',
  baseV5ApiUri: 'https://toyotaus-analytics-apiv5-prod.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/toyota/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: [
    'WebsiteOverview',
    'WebsiteScorecard',
    'ProgramOverview',
    'ChatOverview',
    'DigitalRetailingOverview',
    'ServiceSchedulerOverview',
    'TradeInOverview',
    'SalesForecastingSummary'
  ]
};
